import React, {useState, useEffect, useCallback} from 'react';
import './App.css';
import {cloneDeep, isEqual, remove, findIndex, assign } from 'lodash';
import Lane from "./Lane"
import SpaceModal from './SpaceModal';
import SettingsModal from "./SettingsModal"
import { getDefaultSpaces } from './defaults';
import { Space, CardType, Lane as LaneType} from './types'
import classnames from 'classnames';
import { generateRandomId} from './tools';
import TimBody from './images/tim_body.svg';
import TimFace from './images/tim_face.svg';
import { ReactComponent as IconAdd } from './icons/add-plus.svg'; 
import { ReactComponent as IconSettings } from './icons/settings.svg'; 

function App() {

  const [spaces, setSpaces] = useState<Space[]>([]);
  const [isFocusMode, setIsFocusMode] = useState<boolean>(false)
  const [selectedSpace, setSelectedSpace] = useState<Space | undefined>(undefined);
  const [addSpaceModalShow, setAddSpaceModalShow] = useState<boolean>(false);
  const [settingsModalShow, setSettingsModalShow] = useState<boolean>(false);
  const MAX_ROWS = 8;

  const saveValuesToLocalStorage = (key:string, values: Space[]) => {
    localStorage.setItem(key, JSON.stringify(values));
  };

  const getValuesFromLocalStorage = (key:string): Space[] => {
    const storedValues = localStorage.getItem(key);
    return storedValues ? JSON.parse(storedValues) : [];
  };
  
  const updateValues = useCallback((key:string, newValues: Space[]) => {
    console.log("!!! updating", key, newValues)
    setSpaces(newValues);
    saveValuesToLocalStorage(key, newValues);

    // refresh spaces
    if(key==="spaces"){
      let idx = newValues.findIndex(space => space.name === selectedSpace?.name )
      if(idx === -1){
        idx = 0
      }
      setSelectedSpace(newValues[idx])
    }
  }, [selectedSpace?.name]);

  useEffect(() => {
    const storedValues = getValuesFromLocalStorage('spaces');
    console.log("!!! storedValues, spaces", storedValues, spaces)
    
    if(storedValues.length === 0){
      updateValues('spaces', getDefaultSpaces())
    }
    // if locally stored
    if ( !isEqual(spaces,storedValues) ){
      console.log("!!!! not equal")
      if(storedValues.length > 0 ){
        console.log("!!! locally stored")
        setSpaces(storedValues);
        const pathArray = window.location.pathname.split("/");
        console.log("!!! pathArray", pathArray)

        //load space from url
        if(pathArray[1] && pathArray[1].length > 0){
          const idx = storedValues.findIndex(space => space.name === pathArray[1])
          if(idx > -1){
            setSelectedSpace(storedValues[idx])
          }
        }

        else{
          setSelectedSpace(storedValues[0])
        }
      }else{ //default
        updateValues('spaces', getDefaultSpaces())
      }
    }

  }, [spaces, updateValues]);



  
  // const [savedSpace, setSavedSpace] = useState(getDefaultSpaces()[0].lanes)

//   ### Bulk link opener

// This is useful when you need to open Gmail, Github, GCal and Jira when your routine start.

// If you have more than 2 links as content, a link opener button is added. Click it to try it.
     

  // ---- CARD ADD ----
  const addCard = ( laneIndex: number, index: number) => {    
    let newSpaces : Space[] = cloneDeep(spaces)
    if (selectedSpace){

      const newCard = { 
        content: "", 
        id:generateRandomId(),
        isNew: true,
        category: null
      };

      newSpaces = newSpaces.map(space => {
        if (space.name === selectedSpace.name){
          space.lanes[laneIndex].splice(index, 0, newCard);
        }
        return space
      })
      
      updateValues('spaces', newSpaces)
    }
  }

  // ---- CARD DELETE ----
  const deleteCard = ( id: string) => {    
    console.log("delete card", id)
    let newSpaces : Space[] = cloneDeep(spaces)

    //in selected space, delete card by id
    if (selectedSpace){
      newSpaces = newSpaces.map(space => {
        if (space.name === selectedSpace.name){

          space.lanes.map( (lane) => {
            return remove(lane, {id: id})
          })
          
        }
        return space
      })
      

      updateValues('spaces', newSpaces)
    }
  }

  // ---- CARD UPDATE ----
  const updateCard = ( newCard: CardType) => {    
    console.log("update card", newCard)
    let newSpaces : Space[] = cloneDeep(spaces)

    //in selected space, update card by id
    if (selectedSpace){
      newSpaces = newSpaces.map(space => {
        if (space.name === selectedSpace.name){

          space.lanes.map( (lane) => {
            const itemIndex = findIndex(lane, { id: newCard.id });
            if (itemIndex !== -1) {
              lane[itemIndex]={...newCard, isNew:undefined}
            }
            return lane
          })
        }
        return space
      })      
      updateValues('spaces', newSpaces)
    }
  }


  const deleteLane = (laneIndex: number )=>{
    //clone spaces
    let newSpaces : Space[] = cloneDeep(spaces)

    //in selected space, delete lane by indx
    if (selectedSpace){
      newSpaces = newSpaces.map(space => {
        if (space.name === selectedSpace.name){
          space.lanes.splice(laneIndex, 1);
        }
        return space
      })

      updateValues('spaces', newSpaces)
    }
  }


  const handleAddSpaceModal = () =>{
    setAddSpaceModalShow(true);
  }

  const handleSettingsModal = () =>{
    setSettingsModalShow(true);
  }

  const handleAddSpace = (name:string) =>{
    const newSpaces = cloneDeep(spaces)
    newSpaces.push({
      name: name,
      lanes: [[{id:generateRandomId(), content:`hello`, category:null}]],
    })

    window.location.assign('/'+name);
    setAddSpaceModalShow(false)
    updateValues('spaces', newSpaces)
  }

  const handleSpacesEdit = (newSpaces:Space[])=> {
    setSettingsModalShow(false)
    updateValues('spaces', newSpaces)
  }

  const getStyle = () => {
    if(selectedSpace){
      const percent = 100/selectedSpace.lanes.length 
      return {width:`${percent}%`}
    }
  }

  const handleSpaceClick = (value: string) =>{
    const idx = spaces.findIndex(space => space.name === value)
    if(idx > -1){
      window.location.assign('/'+value);
      setSelectedSpace(spaces[idx])
    }
  }

  const handleAddColumn = (segment: number) => {
    //add lane to selected space if under MAX_ROWS
    if (typeof selectedSpace?.name !== "undefined" && selectedSpace.lanes.length < MAX_ROWS){
      const newSpaces = cloneDeep(spaces).map(space => {
        if (space.name === selectedSpace.name){
          space.lanes.splice(segment, 0, []);
          return space
        }else{
          return space
        }
      })
      updateValues( 'spaces', newSpaces);

    const idx = newSpaces.findIndex(space => space.name === selectedSpace.name)
    setSelectedSpace(newSpaces[idx])
    }

  }

  const getWidth = (idx: number ) => {
    const length = selectedSpace?.lanes?.length ?? 0
    let val = 100
    if(typeof length === "number" && length > 0){
      val = 100/length
    }
    if(idx === 0 || idx === (length)){
      val = val/2
    }
    return val+"%";
  }

  return (
    <div className="App">
      {selectedSpace &&
      <div className='space'>
        {selectedSpace.lanes.map( (lane, idx) => {
          return(
            <div className='lane' style={getStyle()}>
              <Lane idx={idx} cards={lane} deleteLane={deleteLane} addCard={addCard} deleteCard={deleteCard} updateCard={updateCard} />
            </div>
          )
        })}
      </div>
      }

      {!isFocusMode && 
      <div className='footer'>
        <div id="add-lane">
          {[...Array(selectedSpace?.lanes?.length ? selectedSpace?.lanes?.length +1 : 2)].map((item, idx) =>{
            return(<div className="add-lane-segment" onClick={() => {handleAddColumn(idx)}} style={{width: getWidth(idx)}}>Add Lane</div>)
          })
          }
        </div>
          
        <div className='footer-spaces'> 
          <ul >
          {spaces.map((space) => (
            <li className={classnames('footer-list-item', {selected: space.name === selectedSpace?.name})} key={space.name} onClick={()=>{handleSpaceClick(space.name)}}>{space.name}</li>
          ))}
          </ul>
          <button title="Add Space" className="icon-button" onClick={() => {handleAddSpaceModal()}}>
            <IconAdd height="20px" width="20px"  />
          </button>
          <button title="Settings" className="icon-button" onClick={() => {handleSettingsModal()}}>
            <IconSettings height="20px" width="20px"  />
          </button>
        </div>

        <button onClick={() => {updateValues('spaces', getDefaultSpaces())}}>Test</button>
      </div>
      }

      <div id="tim" onClick={()=>{
          setIsFocusMode(!isFocusMode)
        }}>
          <img id="timBody" src={TimBody} alt="logo"/>
          <img id="timFace" src={TimFace} alt="logo"/>
      </div>

      {addSpaceModalShow &&
        <SpaceModal onCancel={()=>{setAddSpaceModalShow(false)}} onCreate={handleAddSpace}/>
      }
      {settingsModalShow &&
        <SettingsModal onCancel={()=>{setSettingsModalShow(false)}} onSave={handleSpacesEdit} spaces={spaces}/>
      }
    </div>
  );
}

export default App;
