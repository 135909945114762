import './App.css';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { ReactComponent as IconReload }  from './icons/external-link.svg'; 
import { CardType } from "./types"

// function renderListItem(props: any) {
//   const { node } = props;
//   return (
//     <li key={node.position.start.offset}>oooo
//       {hasLinksInList(node) && <button>Click Me!</button>}
//       <ReactMarkdown {...props} />
//     </li>
//   );
// }

// interface MarkdownRendererProps {
//   markdownText: string;
// }


// function customList(props: any ) {

//   const links = [
//     "http://google.com",
//     "http://bing.com",
//   ]


//   const { node } = props;
//   const items = node.children.filter( (x: any) => x.type==="element" && x.tagName==="li")
//   return (
//     <>
//     CUSTOM LIST
//     <button onClick={handleOpenLinks}>Click</button>
//     <ul>
//       {items.map((item:any, idx:number) =>{
//         return(
//           <li key={idx}>{JSON.stringify(item)}</li>
//         )
//       })}
//     </ul>
//     </>

//   )
// }


// function MarkdownRenderer({ markdownText }: MarkdownRendererProps) {
//   const components = {
//     ul: customList
//   };

//   return (
//     <>
    
//     <ReactMarkdown remarkPlugins={[remarkGfm]} components={components}>
//       {markdownText}
//     </ReactMarkdown>
//     </>
//   );
// }



export type CardMarkdownProps = {
  card: CardType,
  updateCard: Function,
}

interface NestedObject {
  props: {
    children: (string | NestedObject)[];
  };
}

function insertTildes(inputString: string, indices: number[]) {
  const resultArray = inputString.split('');
  
  indices.forEach(index => {
    if (index === -1) {
      // Insert at the last index
      resultArray.push('~');
    } else {
      // Insert at the specified index
      resultArray.splice(index, 0, '~');
    }
  });

  return resultArray.join('');
}

function removeTildes(inputString: string, indices: number[]) {
  const resultArray = inputString.split('');

  indices.forEach(index => {
    if (index === -1) {
      // Remove from the last index
      resultArray.pop();
    } else {
      // Remove from the specified index
      resultArray.splice(index, 1);
    }
  });

  return resultArray.join('');
}

function toggleStrikethrough(markdown: string, stringsToFind: string[]): string {
  const lines = markdown.split('\n');

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].trim();

    if (line.startsWith('- ') && stringsToFind.every(str => line.includes(str))) {
      console.log("li:", line)
        const isStrikethrough = line[2]==='~' && line[line.length - 1] === '~'
        if (isStrikethrough) {
          lines[i] = removeTildes(line, [2, -1])
        } else {
          lines[i] = insertTildes(line, [2, -1])
        }
    }
  }

  return lines.join('\n');
}

function extractStrings(arr: (string | NestedObject)[]): string[] {
  let result: string[] = [];

  arr.forEach(item => {
    if (typeof item === 'string') {
      result.push(item);
    } else if (typeof item === 'object' && item.props && item.props.children) {
      // Recursive call for nested objects
      result = result.concat(extractStrings(item.props.children));
    }
  });

  return result;
}

function CardMarkdown({card, updateCard}:CardMarkdownProps){


  function extractLinksFromMarkdown(markdownText: string): string[] {
    const linkRegex = /\[(.*?)\]\((?!.*\.(?:png|jpg|jpeg|gif|svg|bmp|webp)(?:[\s)]|$))(.*?)\)/g;
    const links: string[] = [];
    let match;
  
    while ((match = linkRegex.exec(markdownText)) !== null) {
      const [, linkText, url] = match;
      links.push(url);
    }
  
    return links;
  }

  const bulkLinks = extractLinksFromMarkdown(card.content)
  const handleOpenLinks = () => {
    bulkLinks.forEach(link => window.open(link, '_blank'));
  }; 
  


  
  return(
  <>      
    {bulkLinks.length > 0 &&
      <button title="Bulk link opener" className="floating-button icon-button borderless" onClick={handleOpenLinks}>
        <IconReload height="20px" width="20px"  />
      </button>

    }
    <div className='card-content dont-break-out'>
      {/* <MarkdownRenderer markdownText={markdownText} /> */}
      <ReactMarkdown   components={{
    h1: 'h2',
    // ul(props){
    //   console.log("!!! UL props", props)
    //   return <>*{JSON.stringify(props.content)}</>;
    // },
    li(props) {
      console.log("!!! LI props", props)
      return <li onClick={()=>{
        console.log(props.index, props.children)
        const strings = extractStrings(props.children as (string | NestedObject)[])
        console.log(strings)
        // console.log(toggleStrikethrough(card.content, strings))
        const newCard = {...card, content:toggleStrikethrough(card.content, strings) }
        updateCard(newCard)
      }}>{props.children}</li>;
    },
    a(props){ // Stop event propagation on links (to avoid strikelines with links inside lists)
      const handleClick = (event:any) => {
        event.stopPropagation();
      };
      return<a href={props.href} onClick={handleClick}>{props.children}</a>
    },
    }} remarkPlugins={[remarkGfm]} children={card.content}></ReactMarkdown>
    </div>
  </>
  )
}






export default CardMarkdown;
