export function generateRandomId (): string {
    const timestamp = Date.now().toString(36); // Get current timestamp in base36
    const randomString = Math.random().toString(36).substring(2, 9); // Generate random string
  
    return timestamp + randomString;
  }


 export function isValidHttpUrl(string: string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }