import React, {useState, useEffect} from 'react';
import './App.css';

type SpaceModalProps = {
    onCreate: any;
    onCancel: any;
}

function SpaceModal({onCreate, onCancel}:SpaceModalProps){
    const [spaceName, setSpaceName] = useState<string | undefined>()

    function handleCreation  () {
        onCreate(spaceName)
    }

    return(
        <div id="spaceModal" className="modal">
            <label>New space</label>
            <br/><br/>
            <input className="w-100" placeholder='Space name' onChange={(e)=>{setSpaceName(e.target.value)}} value={spaceName}></input>
            <br/>
            <div className='button-row'>
                <button onClick={onCancel} >Cancel</button>
                <button className='primary' onClick={handleCreation} >Create</button>
            </div>

        </div>
    )
}

export default SpaceModal;