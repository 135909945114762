import {Space} from './types'

export const getDefaultSpaces = () : Space[] => {

    return [{
        name:"General",
        lanes:[
    [
      {
        id:"ddvgd", 
        content:`
![alt text](images/logo.png)

# Refresh your workflow

- 📝 Create notes and **todos**.
- 🗞️ Follow rss **feeds** from your favorite sources.
- 📋 **Embed** content to have it at glance (Google Sheets, Calendar...)
- 💼 🎒 🧺 🛍️ Switch context with **spaces**.  
        `,
        category:"markdown"
      },
    ],
    [
      {id:"oidvgd",content:`
### The basics in 20 secs.

Hover on this box, go to **edit mode** (pencil button), delete this line and save with the check button. That's all. 

Reload the page. It's saved in your browser, not in our server.


### A bit deeper in 1min

- Click on this list item to mark it **done**.
- Create a **new box**  (plus button when you're hovering this box) 
- Visit the Spaces on the footer to see how you can use Compartmint in different situations.
- **Delete this box** , going to edit mode and then clicking on the bin button.   
      `, category:"markdown"},
      // {id:"iutrgd",content:"https://airtable.com/embed/shrz3g6tB7GkfzOOB?backgroundColor=red&viewControls=on"},
            // {content:"https://flocus.com/minimalist-pomodoro-timer/"},

      // {content: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSjNx-x74GKQBeKxed-4hq0mRdCGOovWcdGr4N90fIE54pD1GRIIOc3TAa6wOY_EcASZsGRd1P7MMU9/pubhtml?widget=true&amp;headers=false"},
    ]
  ]
}
,{
      name:"Work",
      lanes:[[{id:"ddvntd", content:`hello`, category:"markdown"}]],
},{
      name:"Gym",
      lanes:[],
}
,{
  name:"News",
  lanes:[],
},

]
}


// https://www.lapresse.ca/manchettes/rss
// https://rss.nytimes.com/services/xml/rss/nyt/World.xml
// https://calendar.google.com/calendar/embed
// https://www.youtube.com/embed/lTRiuFIWV54