import {useState, useEffect, useCallback} from 'react';
import './App.css';
import sanitizeHtml from 'sanitize-html';
import { CardType, FeedMode } from './types'
import { ReactComponent as IconCaretCircleDown } from './icons/caret-circle-down.svg'; 
import { ReactComponent as IconReload }  from './icons/reload.svg'; 


export type CardRssProps = {
  card: CardType,
  updateCard: Function,
}

function CardRSS({card, updateCard}: CardRssProps){

const MAX_ARTICLES = 10;
const [articles, setArticles] = useState<any[]>([]);
const [name, setName] = useState<any>("");
const [loaded, setLoaded] = useState<boolean>(false);

const loadFeed = useCallback(() =>{
  const feedUrl = encodeURIComponent(card.content)
  console.log("!!!! feedUrl", feedUrl)
    fetch("https://vymimiqotxjnieghckjz.supabase.co/functions/v1/browser-with-cors?feed="+feedUrl)
  .then((res) => res.json())
  .then((data) => {
    setArticles(data.entries)
    setName(data.title.value)
    setLoaded(true)
  })
  .catch((error) => alert(error));
},[card.content])

useEffect(() => {
  loadFeed();
}, [loadFeed]);

const handleReload = () => {
  setLoaded(false);
   loadFeed();
};

  return(<>

    {loaded && 
    <>
      <button title="Reload" className="floating-button icon-button borderless" onClick={handleReload}>
      <IconReload height="20px" width="20px"/>
      </button>

      <h3 className="card-rss-header">
       {name}
      </h3>

      {articles.length > 0 && <>
        {articles.map(article => {
          const markup = { __html: sanitizeHtml(article.description.value)  };
          return(
            <ul key={article.title}>
              <li className="feed-link">
                <a href={article.links[0].href}>
                {article.title.value}

                {article.updated.value}
                </a>
                {card.feedMode==="descriptive" &&
                  <div dangerouslySetInnerHTML={markup}/>
                }
              </li>
            </ul>
          )
        })}    
      </>}
    </>
    }

    {!loaded && 
    <div className="card-rss-message">
      Loading feed…
    </div>}
  </>)
}


export default CardRSS;
