import { CardType } from "./types"
import Card from './Card';
import { ReactComponent as IconTrash  } from './icons/trash.svg'; 
import { ReactComponent as IconAdd } from './icons/add-plus.svg'; 

export type LaneProps = {
    cards: CardType[],
    deleteLane:Function,
    addCard: Function,
    deleteCard: Function,
    updateCard: Function,
    idx: number
}


function Lane ( {cards, deleteLane, addCard, deleteCard, updateCard, idx} : LaneProps) {

    const  handleAddCard = (cardIdx:number) => {
        console.log("!!! handle add card")
        addCard(idx,cardIdx)
    }

    const  handleDeleteLane = () => {
        console.log("!!! handle delete lane")
        deleteLane(idx)
    }
    return(
        <>
        {cards.map( (card, cardIdx) => {
            return(
              <Card key={card.id} card={card} onClickAdd={()=>{handleAddCard(cardIdx+1)}} deleteCard={deleteCard} updateCard={updateCard} />
            )
          })}
        {cards.length === 0 && 
            <div className="lane-empty">
                <button onClick={()=>handleAddCard(0)}>
                    <IconAdd height="20px" width="20px" />Add Box
                </button>
                                <br/><br/><br/>
                <button className="negative borderless" onClick={()=>handleDeleteLane()}>
                    <IconTrash height="20px" width="20px" />
                    Delete Lane
                    </button>
            </div>
        } 
        </>    
    )

}

export default Lane;