import React, {useState, useEffect} from 'react';
import './App.css';
import { ReactComponent as IconTrash } from './icons/trash.svg'; 
import { ReactComponent as IconUp } from './icons/arrow-up.svg'; 
import { ReactComponent as IconDown } from './icons/arrow-down.svg'; 

import {Space} from "./types"
import {cloneDeep, findIndex} from "lodash"

type SettingsModalProps = {
    onSave: any;
    onCancel: any;
    spaces: Space[];
}



function SettingsModal({onSave, onCancel, spaces}:SettingsModalProps){
    const [spaceName, setSpaceName] = useState<string | undefined>()
    const [localSpaces, setLocalSpaces] = useState<Space[]>(spaces)


    function handleSave  () {
        onSave(localSpaces)
    }

    function moveObjectToNextPosition(arr: Space[], name: string): undefined {
        const newSpaces : Space[] = cloneDeep(arr)
        const index = findIndex(newSpaces, { name: name });
        // Object not found or already at the last position
        if (index === -1 || index === newSpaces.length - 1) {
            return;
        }
        
        // Swap the current object with the next one using destructuring and splice
        [newSpaces[index], newSpaces[index + 1]] = [newSpaces[index + 1], newSpaces[index]];
        
        //update local spaces
          setLocalSpaces(newSpaces)
      }

    function moveObjectToPreviousPosition(arr: Space[], name: string): undefined {
        const newSpaces : Space[] = cloneDeep(arr)
        const index = findIndex(newSpaces, { name: name });
        
        // Object not found or already at the first position
        if (index === -1 || index === 0) {
            return;
        }
        
        // Swap the current object with the previous one using destructuring and splice
        [newSpaces[index], newSpaces[index - 1]] = [newSpaces[index - 1], newSpaces[index]];
        
        //update local spaces
        setLocalSpaces(newSpaces)
    }

    function removeSpace(arr: Space[], name: string) {
        const newSpaces : Space[] = cloneDeep(arr)
        const indexToRemove = arr.findIndex(item => item.name === name);
      
        if (indexToRemove !== -1) {
          // Use splice to remove the item at the found index
          newSpaces.splice(indexToRemove, 1);
        }
      
        setLocalSpaces(newSpaces)
      }

    return(
        <div id="settingsModal" className="modal">
            <label>Manage Spaces</label>
            <br/><br/>
            {localSpaces.map((space) => (
                <div className={'settings-space'} key={space.name} onClick={()=>{}}>
                <div>{space.name}</div>
                <button className="icon-button borderless" onClick={()=>{moveObjectToPreviousPosition(localSpaces, space.name)}}><IconUp height="15px" width="15px" /></button>
                <button className="icon-button borderless" onClick={()=>{moveObjectToNextPosition(localSpaces, space.name)}}><IconDown height="15px" width="15px" /></button>
                <button className="icon-button borderless" onClick={()=>{removeSpace(localSpaces, space.name)}}><IconTrash height="15px" width="15px" /></button>
            </div>
            ))}

<br/>
            <div className='button-row'>
                <button onClick={onCancel} >Cancel</button>
                <button className='primary' onClick={handleSave} >Save</button>
            </div>

        </div>
    )
}

export default SettingsModal;