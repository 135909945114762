import {useState, useRef} from 'react';
import './App.css';
import { ReactComponent as IconAdd } from './icons/add-plus.svg'; 
import { ReactComponent as IconEdit} from './icons/edit.svg'; 
import { ReactComponent as IconExpand} from './icons/expand.svg'; 
import { ReactComponent as IconShrink} from './icons/shrink.svg'; 
import { ReactComponent as IconCheck} from './icons/check.svg';
import { ReactComponent as IconTrash} from './icons/trash.svg';
import { CardType, Category} from './types'
import CardRSS from "./CardRSS"
import CardMarkdown from './CardMarkdown';
import classnames from 'classnames';
import {isValidHttpUrl } from "./tools"


export type CardProps = {
  card: CardType,
  onClickAdd: Function,
  deleteCard: Function,
  updateCard: Function,
}
export type CardEditProps = {
  card: CardType,
  content: string,
  onChange: Function,
}

function CardCategory( {card, content, onChange}: CardEditProps){
  const ref = useRef<HTMLDivElement>(null);

  return(
    <div ref={ref} className='card-category'>
      <button onClick={(e)=>{onChange("markdown")}}>Notes</button>
      <button onClick={(e)=>{onChange("feed")}}>Feed</button>
      <button onClick={(e)=>{onChange("embed")}}>Embedding</button>
   </div>
  )
}

function CardEdit( {card, content, onChange}: CardEditProps){
  const ref = useRef<HTMLDivElement>(null);
  var placeholder=""
  switch (card.category) {
    case "markdown":
      placeholder=`Write your notes here.\n\n\n\nMARKDOWN SYNTAX:\n\n# Header 1\n\n## Header 2\n\n**Bold text**\n\n*Italic text*\n\n~Strikethrough text~\n\n- List item\n- Another item\n\n\n\n`
      break;
      case "embed":
        placeholder=`Enter an embeddable url.\n\nSamples:\n\nhttps://calendar.google.com/calendar/embed\nhttps://www.youtube.com/embed/lTRiuFIWV54`
        break;
      case "feed":
          placeholder=`Enter a valid RSS url.\n\nSamples:\n\nhttps://www.reutersagency.com/feed\nhttps://www.espn.com/espn/rss/news`
          break;
    default:
      placeholder=`......`
      break;
  }
  return(
    <div ref={ref} className='card-edit'>
      <textarea value={content} 
      placeholder={placeholder} onChange={(e)=>{onChange(e.target.value)}} autoFocus={card.isNew} />
    </div>
  )
}

export type CardIFrameProps = {
  content: string,
}
function CardIFrame({content}:CardIFrameProps){

  return(
    <iframe className='card-iframe' title={content} src={content} allowFullScreen /> 
  )
}


function Card({card, onClickAdd, deleteCard, updateCard}: CardProps){
  const [isEdit, setIsEdit] = useState(card.isNew === true && card.category !== null)
  const [isExpanded, setIsExpanded] = useState(false)
  const [content, setContent] = useState(card.content)
  const [category, setCategory] = useState(card.category)

  const isRss = card.category==="feed"
  const isIframe = card.category==="embed"
  
  function getCardContent (card: CardType){

    switch(card.category){
      case null:
          return <CardCategory card={card} content={content} onChange={handleCategoryChange} />
          case "feed":
            return <CardRSS card={card} updateCard={updateCard}/>
          case "embed":
            return <CardIFrame content={card.content}/>
          case "markdown":
            return <CardMarkdown card={card} updateCard={updateCard} />          
      default:
        return "something here"+JSON.stringify(card)
    }

  }

  const handleDeleteClick = () =>{
    setIsEdit(false);
    deleteCard(card.id);
  }

  const handleUpdateClick = () =>{
    setIsEdit(false);
    const newCard = {...card, content:content}
    // feed: add feedMode
    if (card.category==="feed" && !card.feedMode){
      newCard.feedMode="compact"
    }
    // no feed: delete feedMode
    if (card.category!=="feed" && card.feedMode){
      delete newCard.feedMode
    }
    console.log("!!! new card", newCard)
    updateCard(newCard)
  }

  const handleContentChange = (val:string)=>{
    setContent(val)
  }

  const handleCategoryChange = (val:Category)=>{
    // setCategory(val)

    const newCard = {...card, category:val, isNew: true}
    updateCard(newCard)
    setIsEdit(true)
  }

  const isSaveDisabled = (card.category === "feed" ) && !isValidHttpUrl(content)

  return(
    <div  className={classnames('card',{'card-expanded':isExpanded})}>
    {isEdit && 
    <>
      <CardEdit card={card} content={content} onChange={handleContentChange} />
      <div className='card-separator'>

          <button disabled={isSaveDisabled} onClick={()=>{handleUpdateClick()}} className={classnames('side-button',{'disabled': isSaveDisabled})}>
            <IconCheck height="15px" width="15px" />
          </button>

          <button onClick={()=>{handleDeleteClick()}} className='side-button negative'>
            <IconTrash height="15px" width="15px"  />
          </button>
        </div>
      </>
    }
    
    {!isEdit && 
    <div className='card-display' style={{padding: card.category === "embed" ? "0" : "0 20px" }}>
      {getCardContent(card)}
    </div>
    }

    {!isEdit && card.category !== null && 
      <div className='card-separator'>
        <button onClick={()=>{setIsEdit(true)}} className='side-button hidden-if-unhover'>
          <IconEdit height="15px" width="15px"  />
        </button>
        <button onClick={()=>{setIsExpanded(!isExpanded)}} className='side-button hidden-if-unhover'>
          {isExpanded &&
            <IconShrink height="15px" width="15px"  />
          }
          {!isExpanded &&
            <IconExpand height="15px" width="15px"  />
          }
        </button>
        {!isExpanded &&
        <button onClick={()=>{onClickAdd(card.content)}} className='side-button hidden-if-unhover'>
          <IconAdd height="15px" width="15px"  />
        </button>
        }
      </div>
    }



    </div>
  )
}

export default Card;
